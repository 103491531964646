.contactPage {
	background-color: whitesmoke;
}

#contactForm {
	width: 25em;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#contactForm::after {
	box-shadow: 1px 2px 3px 4px rgba(20,20,20,0.4);
}

@media (max-width: 450px) {
	#contactForm > * {
		margin: 0 5px;
	}
}