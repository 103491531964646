.navbar {
	max-height: 4em;
	display: flex;
	gap: 25px;
	justify-content: space-between;
	align-items: center;
	padding: 5px 0 5px 0;
	background-color: var(--background-color);
}

#navNameContainer {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	align-items: center;
	gap: 5px;
	margin-right: 5px;
}

#navNameBackground {
	background: white;
	opacity: 0.8;
    width: 10.5em;
    height: 2.5em;
	border-radius: 5px;
}

#navName {
	width: 10em;
	border: none;
	/* animation: riseAndFadeIn 1s forwards ease-in-out; */
	margin: 5px;
}

#bandLogo {
	width: 3em;
	margin-left: 5px;
}

.navicon {
	font-size: 2.5em;
	font-weight: 600;
	padding: 5px;
	color: white;
	border-radius: 25px;
}

.navlinks {
	display: inline-block;
	margin: 5px;
}

.active {
	background-color: var(--accent);
	border-radius: 25px;
	height: 100%;
}

#facebookIcon {
	width: 50px;
	margin: 5px;
	border-radius: 25px;
}

.navicon:hover, #facebookIcon:hover {
	animation: hoverFade 0.3s forwards ease-in-out;
}

/* Hamburger Styling */
.container {
	display: inline-block;
	cursor: pointer;
	margin-left: 10px;
	margin-top: 5px;
}

.container.change {
	margin-left: 13em;
}
  
.bar1, .bar2, .bar3 {
	width: 35px;
	height: 5px;
	background-color: white;
	margin: 6px 0;
	transition: 0.4s;
	border-radius: 2em;
}
  
.change .bar1 {
	transform: translate(0, 11px) rotate(-45deg);
}
  
.change .bar2 {
	opacity: 0;
}
  
.change .bar3 {
	transform: translate(0, -11px) rotate(45deg);
}

.hamburgerMenu {
	width: 16em;
	height: 100vh;
	z-index: 99;
	background-color: var(--background-color);
	margin-left: -20px;
	top: 0;
	box-shadow: 2px 2px 30px 5px var(--secondary);
	opacity: 0.97;
	position: absolute;
}

.slideIn {
	animation: slideIn 0.75s forwards ease-in-out;
}

.slideOut {
	animation: slideOut 0.75s forwards ease-in-out;
}

#hamburgerNavLinkContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 2em;
}

#hamburgerNavLinkContainer .navlinks {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 0 1em;
	text-decoration: none;
	color: white;
	border-radius: 0;
	width: 13.7em;
}

@media (max-width: 1000px) {
	.alertBar {
		height: min-content !important;
		padding: 2px !important;
	}
}

@media (max-width: 787px) {
	.alertBar {
		width: 100%;
	}
	.alertBarText {
		max-width: 100%;
	}
}