.homePage {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	overflow: hidden;
	gap: 20px;
	min-height: 100vh;
	background-color: var(--background-color);
}

#heroImage {
	border-radius: 5px;
	width: 100%;
	height: fit-content;
}

#bandNameSection {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
	margin-top: -10%;
}

.bandNameBackground, #bandName {
  display: block;
  height: 100%;
}

#logoSection {
	width: 100%;
	display: none;
	height: auto;
	justify-content: center;
}

.homePageLogo {
	display: none;
	width: 10%;
}

@media (max-width: 1500px) {
	#bandNameSection {
		margin-top: -10%;
	}
}

@media (max-width: 1200px) {
	#bandNameSection {
		margin-top: -5%;
	}
}

@media (max-width: 920px) {
	.homePage {
		flex-direction: column;
		align-items: center;
	}
	#bandNameSection {
		margin-top: -10%;
		transform: scale(0.5);
	}
	#logoSection, .homePageLogo {
		display: flex;
	}
}

@media (max-width: 730px) {

}

@media (max-width: 575px) {
	#bandNameSection {
		transform: scale(0.4);
	}

	.homePageLogo {
		width: 25%;
	}
}

@media (max-width: 400px) {
	.homePageLogo {
		width: 50%;
	}
}