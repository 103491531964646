.title {
	flex-grow: 1;
}

#latestVideosSection {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;
}

.loadingMessage {
	text-align: center;
}

.loadingSection {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: white;
	padding-bottom: 20px;
}

.videosSection {
	margin-bottom: 2em;
}

.moreVideosText {
	text-align: center;
}

@media (max-width: 1011px) {
	.title {
		margin-bottom: 1em;
	}
}

@media (max-width: 650px) {
	#latestVideo {
		width: 400px;
	}
}

@media (max-width: 420px) {
	#latestVideoWrapper {
		margin-bottom: 10px;
	}
	#latestVideo {
		width: 300px;
	}
	.videoItem {
		max-width: 100%;
		border-radius: 0;
	}
	.videoImage a img {
		width: 250px;
	}
}