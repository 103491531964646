.inputWrapper {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}

.label {
	font-size: 1.125em;
	color: var(--primary)
}

.input, .PhoneInputInput, .textarea, .PhoneInputCountrySelect {
	padding: 10px;
	font-size: 1.125em;
	background-color: var(--background-color);
	color: white;
	font-family: 'Cabin', sans-serif;
	font-family: 'Open Sans', sans-serif;
	border-radius: 0;
	box-shadow: 1px 2px 3px 2px rgba(20,20,20,0.4);
}

.textarea {
	min-height: 10em;
}

.error {
	z-index: 0;
	background-color: var(--accent);
	color: white;
	padding: 5px;
	border-radius: 0 0 15px 15px;
	animation: sinkAndFadeIn .5s forwards;
}

.PhoneInput + .error {
	margin-left: 40px;
}