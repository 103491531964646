:root {
	--background-color: #182128;
	--accent: #df2727;
	--primary: #737475;
	--secondary: #1b73f1;
	--tertiary: #8c9494;
}

body {
	/* background-color: var(--background-color); */
	font-family: 'Cabin', sans-serif;
	font-family: 'Open Sans', sans-serif;
}

.page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	background-color: whitesmoke;
}

/* Animations */
@keyframes hoverFade {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	25% {
		background-color: var(--accent);
		transform: scale(1.2);
	}
	100% {
		opacity: 0.2;
		transform: scale(1);
		background-color: var(--accent);
	}
}

@keyframes riseAndFadeIn {
	0% {
		opacity: 0;
		transform: translateY(5em);
	}
	75% {
		transform: translateY(-1em);
	}
	100% {
		opacity: 1;
		transform: translateY(0em);
	}
}

@keyframes riseAndFadeOut {
	0% {
		opacity: 1;
		transform: translateY(0em);
	}
	25% {
		transform: translateY(0.5em);
	}
	100% {
		opacity: 0;
		transform: translateY(-5em);
	}
}

@keyframes sinkAndFadeIn {
	0% {
		opacity: 0;
		transform: translateY(-5em);
	}
	75% {
		transform: translateY(0.5em)
	}
	100% {
		opacity: 1;
		transform: translateY(0em);
	}
}

@keyframes sinkAndFadeOut {
	0% {
		opacity: 1;
		transform: translateY(0em);
	}
	25% {
		transform: translateY(0.5em);
	}
	100% {
		opacity: 0;
		transform: translateY(-5em);
	}
}

@keyframes loading {
	0% {
		transform: rotate(0deg)
	}

	15% {
		transform: rotate(-45deg)
	}

	50% {
		transform: rotate(720deg)
	}

	100% {
		transform: rotate(360deg)
	}
	
}

@keyframes slideIn {
	0% {
		transform: translateX(0)
	}
	100% {
		transform: translateX(-50vw)
	}
}

@keyframes slideOut {
	0% {
		transform: translateX(-50vw)
	}
	100% {
		transform: translateX(0)
	}
}