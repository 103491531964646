.gigCard {
	background-color: var(--background-color);
	display: flex;
	flex-direction: column;
	text-align: center;
	padding: 5px;
	margin-bottom: 20px;
	box-shadow: 1px 2px 3px 4px rgba(20,20,20,0.4);
	color: white;
	border-radius: 20px;
	min-height: 26em;
	align-items: center;
	position: relative;
	margin: 1em;
}

.dateWrapper {
	display: flex;
	gap: 5px;
	justify-content: center;
	left: 5%;
}

.gigTime {
	right: 5%;
}

.dateWrapper, .gigTime {
	position: absolute;
	top: 2%;
}

.date0, .date1, .date2, .location0, .location1 {
	font-weight: bold;
}

.date1 {
	color: var(--accent);
}

.locationWrapper {
	display: flex;
	flex-direction: column;
	margin-top: 2em;
}

.location1 {
	color: var(--accent);
}

a.location0 {
	color: white;
}

a.location0:hover {
	color: var(--accent);
}

a.location0:active {
	opacity: 0.5;
}

.gigImage {
	margin-top: 1em;
}


.description {
	margin: 1em 0;
	min-width: 20em;
	max-width: 20em;
	overflow-y: auto;
}

.type {
	position: absolute;
	bottom: 2%;
	font-weight: bold;
}

@media (max-width: 700px) {
	.gigCard {
		max-width: 20em;
		font-size: 0.9rem;
		min-height: 28em
	}
	.description {
		min-width: 18em;
	}
}

@media (max-width: 450px) {
	.gigCard {
		max-width: 17.5em;
		font-size: 0.9rem;
		min-height: 30em
	}
	.description {
		min-width: 15em;
	}
}
