#latestVideo {
	margin-bottom: 20px;
	border: 0;
}

#latestVideoWrapper {
	background-color: var(--background-color);
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 5px;
	margin-bottom: 20px;
	box-shadow: 1px 2px 3px 4px rgba(20,20,20,0.4);
}

.videoTitle {
	margin: 0 1em;
	color: white;
}


.videoItem:hover {
	opacity: 0.8;
	transform: scale(1.05);
}

.button:focus {
	border: 2px solid var(--accent);
}

.videoItem:active {
	opacity: 0.7;
	transform: scale(0.9);
}

.videoItem {
	max-width: 375px;
	margin: 0;
	padding: 5px;
	border-radius: 20px;
	background-color: var(--background-color);
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	box-shadow: 1px 2px 3px 4px rgba(20,20,20,0.4);
};

.videoImage a img {
	border-radius: 5%;
}
